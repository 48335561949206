export enum ContentType {
    SNACKS = 'snacks',
    COMMODITIES = 'commodities',
    MEALS = 'meals',
    SURVEY = 'survey',
    TRANSFER = 'transfer',
}

export enum DeliveryState {
    DRAFT = 0,
    ISSUED = 10,
    ISSUED_WITH_NOTES = 20,
    REJECTED_BY_SCHOOL = 30,
    RETURNED_BY_DRIVER = 40,
}

export enum OrderState {
    REQUESTED = 'REQUESTED', //Draft pink
    CONFIRMED = 'CONFIRMED', //yellow
    LOADED = 'LOADED', //blue
    DONE = 'DONE', //green
    OVERDUE = 'OVERDUE', //red
    DISCARDED = 'DISCARDED', //grey
}

export enum OrderItemState {
    CONFIRMED = 'CONFIRMED', //yellow
    DELIVERED = 'DELIVERED', //CELESTE
    DISCARDED = 'DISCARDED', //grey
    DONE = 'DONE', //green
    LOADED = 'LOADED', //blue
    PLANNED = 'PLANNED', //pink
}

export enum Role {
    SupplierDriver = 'supplierdriver',
    SchoolDriver = 'schooldriver',
    PartnerManager = 'partnermanager',
    PartnerMonitor = 'partnermonitor',
    PartnerStaff = 'partnerstaff',
    SchoolRepresentative = 'schoolrepresentative',
    SchoolManager = 'schoolmanager',
    SupplierManager = 'suppliermanager',
    MinistryReporter = 'ministryreporter',
    WFPReporter = 'wfpreporter',
    WFPManager = 'wfpmanager',
}

export enum LogType {
    CRUD = 'crud',
    LOGIN = 'login',
    REQUEST = 'request',
}

export const RolesWithEntity = [Role.SchoolRepresentative, Role.SupplierDriver, Role.SupplierManager, Role.PartnerManager, Role.PartnerStaff];

export interface Crate {
    id: number;
    content: string;
    crateType: number;
    contentName?: string;
    losses?: number[];
    objectId: number;
    orderItem: number;
    quantity: number;
    acceptedQuantity: number;
    returnItem?: ReturnItem;
}

export interface SchoolMeal {
    id: number;
    contentTitle: string;
    deliveryDate: string;
    items: SchoolMealItem[];
    meal: number;
    mealName: string;
    state: string;
    school: number;
    schoolName: string;
    totalQuantityAm: number;
    totalQuantityPm: number;
    updatedOn: string;
}

export interface SchoolMealItem {
    code: number;
    id: number;
    centralSchoolName: string;
    createdOn: string;
    driver: number;
    school: number;
    schoolMeal: number;
    schoolName: string;
    state: string;
    meal: number;
    mealName: string;
    quantity: number;
    quantityAm: number;
    quantityPm: number;
    estimateDeliveryDate: string;
}
export interface CrateReclaim {
    id: number;
    createdOn?: string;
    quantity?: number;
    driver: number;
    driverUsername?: string;
    school: number;
    schoolName?: string;
    supplier: number;
    supplierName?: string;
}

export interface CrateReclaimSupplierReport {
    school: string;
    supplier: string;
    claims: number;
    quantities: number;
}


export interface Commodity {
    id: number;
    disabled: boolean;
    name: string;
    nameAr: string;
    nameEn: string;
    unitOfMeasure: number;
    unitOfMeasureName: string;
    unitOfMeasureSymbol: string;
}

export interface CommoditiesInfo {
    name: string;
    acceptedQuantity: number;
    symbol: string;
}

export interface CommoditySchoolItem {
    id: number;
    commodity: number;
    commodityName: string;
    unitOfMeasure: number;
    unitOfMeasureName: string;
    quantity: number;
}

export interface Governorate {
    id: number;
    name: string;
    nameAr: string;
    nameEn: string;
}

export interface KVPair {
    [key: string]: string | string[];
}

export interface Meal {
    id: number,
    name: string,
}

export interface ListPage<T> {
    count: number;
    next: string | null;
    pageNumber: number;
    pageSize: number;
    previous: string | null;
    results: T[];
    filtering?: KVPair[];
    sorting?: string[];
}

export interface LogEntry {
    datetime: string;
    username?: string;
    method: string;
    url: string;
    action?: string;
    contentType?: string;
    contentId?: string;
}

export interface Loss {
    id: number;
    quantity: number;
    author: number;
    crate: number;
    category: number;
    categoryName: string;
    contentName: string;
}

export interface LossCategory {
    id: number;
    name: string;
    nameAr: string;
    nameEn: string;
}
export interface SnackBySchool {
    school: string;
    snack: string;
    snacks: number;
    snacksAm: number;
    snacksPm: number;
}
export interface CrateBySchool {
    school: string;
}
export interface OrderItemChange {
    id: number,
    objectId1: number,
    objectName1: string,
    quantityAm1: number,
    quantityPm1: number,
    objectId2: number,
    objectName2: string,
    quantityAm2: number,
    quantityPm2: number,
}

export interface OrderItemChangeHistory {
    id: number,
    author: number,
    authorName: string,
    createdOn: string,
    data: OrderItemChange,
}

export interface District {
    id: number;
    governorate: number;
    name: string;
    nameAr: string;
    nameEn: string;
}

export interface Location {
    id: number;
    district: number;
    name: string;
    nameAr: string;
    nameEn: string;
    pcode: string;
}

export interface LossBySchool {
    category: string;
    losses: number;
    school: string;
    snack: string;
}

export interface LoginByEntity {
    username: string;
    entity: string;
    logins: number;
    failures: number;
    logouts: number;
}

export interface Order {
    id: number;
    contentTitle: ContentType;
    deliveryDate: string;
    distributionDate: string;
    state: string;
    numberOfItems?: number;
    manager: number;
    managerName: string;
    mixedState: boolean;
    partner: number;
    partnerName: string;
    supplier: number;
    supplierName: string;
    totalQuantityAm: number;
    totalQuantityPm: number;
    updatedOn: string | null;
}

export interface OrderSupplierReport {
    supplier: string;
    orders: number;
    confirmed: number;
    completed: number;
    loaded: number;
    requested: number;
    overdue: number;
}

export interface OrderItemSchool {
    school: string;
    count: number;
    completed: number;
    overdue: number;
    snacks: number;
    returns: number;
    losses: boolean;
}

export interface OrderTransition {
    id: number;
    transition: string;
    fromState: string;
    toState: string;
    timestamp: string;
    order: number;
    user: number;
    username: string;
    position?: OrderItemTransitionPoint;
}

export interface OrderHistoryData {
    createdOn: string;
    state: string;
    items: OrderItemHistoryData[]
    transitions: OrderTransition[]
}

export interface OrderItem {
    id: number;
    code: string;
    contentNames: string[] | null;
    commoditiesInfo?: CommoditiesInfo[];
    crates: Crate[] | null;
    deliveryState: number | null;
    estimateDeliveryDate: string;
    quantityAm: number;
    quantityPm: number;
    state: string;
    driver: number | null;
    driverName: string | null;
    order: number;
    partnerName: string;
    quantity: string;
    losses?: Loss[];
    school: number;
    schoolName: string;
    schoolRef: string;
    supplier : number;
    supplierName: string;
    updatedOn: string | null;
}

export interface OrderItemHistoryData {
    codeValue: string;
    schoolName: string;
    state: string;
    transitions: OrderItemTransition[]
}

export interface OrderItemTransition {
    id: number;
    transition: string;
    fromState: string;
    toState: string;
    timestamp: string;
    orderItem: number;
    user: number;
    username: string;
    position?: OrderItemTransitionPoint;
}

export interface Report {
    district?: string | null;
    fromDate: string | null;
    partner?: string | null;
    untilDate: string | null;
}

export interface ReturnItem {
    id: number;
    contentName: string;
    originalQuantity: number;
    quantity: number;
    author: number;
    authorName: string;
    crate: number;
    reason: number;
    reasonName: string | null;
}

export interface ReturnReason {
    id: number;
    name: string;
    nameAr: string;
    nameEn: string;
}

export interface School {
    id: number;
    cerd: string;
    district: number;
    districtName: string;
    location: number;
    locationName: string;
    name: string;
    nameAr: string;
    nameEn: string;
    numberOfStudentsAm: number;
    numberOfStudentsPm: number;
    parent: number | null;
}

export interface SchoolAcceptanceTiming {
    school: string;
    avg: number;
    max: number;
    min: number;
    count: number;
    stdev: number;
}

export interface UnitOfMeasure {
    id: number;
    disabled: boolean;
    name: string;
    nameAr: string;
    nameEn: string;
    symbol: string;
}

export interface WeekSupplierSchoolPlan {
    id: number;
    district: number;
    districtName: string;
    name: string;
    days: SchoolSupplierDay[]
}

export interface SchoolSupplierDay {
    id: number;
    estimatedDeliveryTime: string;
    school: number;
    schoolName: string;
    supplierDay: number;
    updateOn: string;
    snacks: SnackSchoolDay[];
}

export interface SchoolMealTransition {
    id: number;
    transition: string;
    fromState: string;
    toState: string;
    timestamp: string;
    order: number;
    user: number;
    username: string;
}

export interface SchoolMealHistoryData {
    createdOn: string;
    state: string;
    items: SchoolMealItemHistoryData[]
    transitions: SchoolMealTransition[]
}

export interface SchoolMealItemHistoryData {
    codeValue: string;
    schoolName: string;
    state: string;
    transitions: SchoolMealItemTransition[]
}

export interface SchoolMealItemTransition {
    id: number;
    transition: string;
    fromState: string;
    toState: string;
    timestamp: string;
    orderItem: number;
    user: number;
    username: string;
}

export interface ServerError {
    status?: number;
    detail?: string[];
    errors?: string[];
}

export interface Snack {
    id: number;
    category: number;
    categoryName: string;
    commodity: number;
    commodityName: string;
    name: string;
    nameAr: string;
    nameEn: string;
}

export interface SnackCategory {
    id: number;
    name: string;
    nameAr: string;
    nameEn: string;
}

export interface SnackSchoolDay {
    id: number;
    quantityAm: number;
    quantityPm: number;
    schoolSupplierDay: number;
    snack: number;
    snackName: string;
    updateOn: string;
}

export interface Sortable {
    code: string;
    field: string;
    label?: string;
    mode: number;
    enabled: boolean;
}

export interface Supplier {
    id: number;
    name: string;
    nameAr: string;
    nameEn: string;
    phone?: string;
}

export interface SupplierDay {
    id: number;
    day: number;
    partnerWeek: number;
    schools: SchoolSupplierDay[];
    supplier: number;
    supplierName: string;
    totals: SupplierDayTotals[];
    updatedOn: string;
}

export interface SupplierDayTotals {
    snackName: string;
    totalAm: number;
    totalPm: number;
}

export interface Survey {
    id: number;
    author: number;
    authorName: string;
    surveySchool: number;
}

export interface Partner {
    id: number;
    name: string;
    nameAr: string;
    nameEn: string;
    phone?: string;
}

export interface PartnerSchool {
    id: number;
    partner: number;
    partnerData?: Partner;
    school: number;
    schoolData?: School;
}

export interface PartnerSupplier {
    id: number;
    partner: number;
    partnerData?: Partner;
    supplier: number;
    supplierData?: Supplier;
}

export interface PartnerWeek {
    id: number;
    author: number;
    authorName: string;
    date: string;
    partner: number;
    partnerName: string;
    year: number;
    updateOn: string;
    week: number;
    suppliers: Supplier[];
}

export interface OrderItemTransitionPoint {
    latitude: string;
    longitude: string;
    accuracy: string;
    altitude?: string;
    altitudeAccuracy?: string;
    user: number;
    username?: string;
    orderItem: number;
    measuredOn?: string;
    state: string;
}


export interface TransferOrderHistoryData {
    codeValue: string;
    schoolName: string;
    state: string;
    transitions: TransferItemHistory[]
}

export interface TransferItemHistory {
    id: number;
    transition: string;
    fromState: string;
    toState: string;
    timestamp: string;
    transfer: number;
    user: number;
    username: string;
    position?: TransferTransitionPoint;
}

export interface TransferTransitionPoint {
    longitude: string;
    latitude: string;
    accuracy?: string;
    altitude?: string;
    altitudeAccuracy?: string;
    state?: string;
    transfer: number;
    user?: number;
    username?: string;
    measuredOn?: string;
}

export interface TransferOrder {
    id: number;
    author: number;
    authorName: string;
    code: string;
    contentTitle: ContentType;
    deliveryDate: string;
    manager: number;
    managerName?: string;
    items: TransferItem[];
    fromSchool: number;
    fromSchoolName?: string;
    toSchool: number;
    toSchoolName?: string;
    createdOn: string;
    state: string;
}

export interface TransferItem {
    id: number;
    snack: string;
    snackName: string;
    quantity: number;
}
export interface UserDetails {
    pk: number;
    username: string;
    email: string;
    entity?: number;
    entityName?: string;
    firstName: string;
    lastName: string;
    groups: string[];
    role: Role;
    updatedOn: string | null;
}

export interface Wizard {
    id: number;
    data: any;
    title: string;
}
