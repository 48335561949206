import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Blockquote } from "@wfp/ui";
import { ServerError, Loss, OrderItem, Role, ReturnItem } from "../../models/types";
import {
    handoverOrderItem,
    pickupOrderItem,
    acceptOrderItem,
    rejectOrderItem, undoPickupOrderItem, acceptReturnOrderItem,
} from "../../restapi/order-item";
import { saveLoss } from "../../restapi/loss";
import { saveReturnItem } from "../../restapi/return-item";
import { useRetrieveOrderItem } from "../../hooks/order-item";
import { useLoggedUser } from "../../hooks/user";
import { LoadingCenter } from "../commons/loading-center";
import { OrderItemCard } from "./card";
import { AcceptConfirmation } from "./accept-confirmation";
import { HandoverConfirmation } from "./handover-confirmation";
import { LossConfirmation, LossError } from "./loss-confirmation";
import { ModifyConfirmation, ReturnItemError } from "./modify-confirmation";
import { RejectConfirmation } from "./reject-confirmation";
import { ReturnConfirmation } from "./return-confirmation";
import { datetimeFormat } from "../../hooks/formats";
import moment from "moment";
import { getCurrentPosition } from "../../hooks/position";
import { sendOrdetItemPosition } from "../../restapi/position";

export const OrderItemDetail = () => {
    const { id } = useParams();
    const user = useLoggedUser({});
    const navigate = useNavigate();
    const [orderItem, setOrderItem] = useState<OrderItem>();
    const [error, setError] = useState<ServerError>();
    const [orderItemHandover, setOrderItemHandover] = useState<OrderItem>();
    const [orderItemAccept, setOrderItemAccept] = useState<OrderItem>();
    const [orderItemReturn, setOrderItemReturn] = useState<OrderItem>();
    const [orderItemReject, setOrderItemReject] = useState<OrderItem>();
    const [orderItemLoss, setOrderItemLoss] = useState<OrderItem>();
    const [orderItemModify, setOrderItemModify] = useState<OrderItem>();
    const [errorsLoss, setErrorsLoss] = useState<LossError[]>();
    const [errorsModify, setErrorsModify] = useState<ReturnItemError[]>();
    const [showLoader, setShowLoader] = useState<boolean>(false);


    const item = useRetrieveOrderItem({ id: Number(id), onError: (e) => setError(e) });

    useEffect(() => {
        if (!!item) {
            setOrderItem(item);
        }
    }, [item]);


  
    function sendPosition(state: string, navigate: () => void) {
        const measuredOn = datetimeFormat(moment(new Date())) || undefined;
        if (!!user) {
            setShowLoader(true);
            getCurrentPosition().then((pos) => {
                sendOrdetItemPosition(
                    {
                        latitude: pos.coords.latitude.toFixed(6),
                        longitude: pos.coords.longitude.toFixed(6),
                        altitude: pos.coords.altitude?.toFixed(6) || undefined,
                        accuracy: pos.coords.accuracy?.toFixed(12),
                        altitudeAccuracy: pos.coords.altitudeAccuracy?.toFixed(12) || undefined,
                        measuredOn: measuredOn,
                        state: state,
                        orderItem: Number(id),
                        user: user.pk,
                    }
                ).catch(error => setError(error));
            }).catch(error => setError(error))
                .finally(() => {
                    setShowLoader(false);
                    navigate();
                });
        } else {
            navigate();
        }
    }

    function handlePickup() {
        if (!!orderItem) {
            pickupOrderItem(orderItem.id)
                .then((data) => sendPosition(data.state, () => navigate('/home')))
                .catch((error) => setError(error));
        }
    }

    function handleUndoPickup() {
        if (!!orderItem) {
            undoPickupOrderItem(orderItem.id)
                .then((data) => sendPosition(data.state, () => navigate('/home')))
                .catch((error) => setError(error));
        }
    }

    function handleHandover() {
        if (!!orderItem) {
            setOrderItemHandover(orderItem);
        }
    }

    function handleHandoverConfirm() {
        if (!!orderItemHandover) {
            handoverOrderItem(orderItemHandover.id)
                .then((data) => sendPosition(data.state, () => navigate('/home')))
                .catch((error) => setError(error));
        }
    }

    function handleAccept() {
        setOrderItemAccept(orderItem);
    }

    function handleAcceptConfirm() {
        if (!!orderItemAccept) {
            acceptOrderItem(orderItemAccept.id)
                .then((data) => sendPosition(data.state, () => navigate('/home')))
                .catch((error) => setError(error));
        }
    }

    function handleAcceptReturn() {
        setOrderItemReturn(orderItem);
    }

    function handleAcceptReturnConfirm() {
        if (!!orderItemReturn) {
            acceptReturnOrderItem(orderItemReturn.id)
                .then((data) => {
                    sendPosition(data.state, () => navigate('/home'));
                    setOrderItemReturn(undefined);
                })
                .catch((error) => setError(error));
        }
    }

    function handleAcceptReturnClose() {
        setOrderItemReturn(undefined);
        setError(undefined);
    }

    function handleLoss() {
        setOrderItemLoss(orderItem);
        setErrorsLoss(undefined);
    }

    function handleLossConfirm(losses: Loss[]) {
        if (!!losses) {
            const errors = new Array<LossError>(losses.length);
            const promises = losses.map((loss, idx) => saveLoss(loss).catch((error) => {
                errors[idx] = error;
                setErrorsLoss([...errors]);
            }));
            Promise.all(promises).then(() => {
                if (!(errors || []).find((e) => !!e ? !Object.values(e).every((f) => !f) : false)) {
                    setOrderItemLoss(undefined);
                    setErrorsLoss(undefined);
                }
            });
        }
    }

    function handleModify() {
        setOrderItemModify(orderItem);
        setErrorsModify(undefined);
    }

    function handleModifyConfirm(returnItems: ReturnItem[]) {
        if (!!orderItemModify) {
            const errors = new Array<ReturnItemError>(returnItems.length);
            const promises = returnItems.map((ri, idx) => ri.quantity === 0 ? Promise.resolve(ri) : saveReturnItem(ri).catch((error) => {
                errors[idx] = error;
                setErrorsModify([...errors]);
            }));
            Promise.all(promises).then(() => {
                if (!(errors || []).find(e => !!e ? !Object.values(e).every(f => !f) : false)) {
                    /* #205868: returned items need to be accepted by driver via accept_return
                    acceptOrderItem(orderItemModify.id)
                        .then(() => navigate('/home'))
                        .catch((error) => setError(error)); */
                    navigate('/home');
                }
            });
        }
    }

    function handleReject() {
        if (!!orderItem) {
            setOrderItemReject(orderItem);
        }
    }

    function handleRejectConfirm(rejectReasonId: number) {
        if (!!orderItem) {
            rejectOrderItem(orderItem.id, rejectReasonId)
                .then((data) => sendPosition(data.state, () => navigate('/home')))
                .catch((error) => setError(error));
        }
    }

    return (
        <>
            {showLoader ? <LoadingCenter /> : (
                <>
                    {!!orderItemHandover && (
                        <HandoverConfirmation
                            orderItem={orderItemHandover}
                            onCancel={() => setOrderItemHandover(undefined)}
                            onOk={handleHandoverConfirm}
                        />
                    )}
                    {!!orderItemAccept && (
                        <AcceptConfirmation
                            orderItem={orderItemAccept}
                            onCancel={() => setOrderItemAccept(undefined)}
                            onOk={handleAcceptConfirm}
                        />
                    )}
                    {!!orderItemReturn && (
                        <ReturnConfirmation
                            error={error}
                            orderItem={orderItemReturn}
                            onCancel={handleAcceptReturnClose}
                            onOk={handleAcceptReturnConfirm}
                        />
                    )}
                    {!!orderItemReject && (
                        <RejectConfirmation
                            orderItem={orderItemReject}
                            onCancel={() => setOrderItemReject(undefined)}
                            onOk={handleRejectConfirm}
                        />
                    )}
                    {!!orderItemLoss && (
                        <LossConfirmation
                            orderItem={orderItemLoss}
                            errors={errorsLoss}
                            onCancel={() => setOrderItemLoss(undefined)}
                            onOk={handleLossConfirm}
                        />
                    )}
                    {!!orderItemModify && (
                        <ModifyConfirmation
                            orderItem={orderItemModify}
                            errors={errorsModify}
                            onCancel={() => setOrderItemModify(undefined)}
                            onOk={handleModifyConfirm}
                        />
                    )}
                    {!!error?.errors && (
                        <Blockquote kind="error" withIcon>{error?.errors?.toString()}</Blockquote>
                    )}
                    {!!error?.detail && (
                        <Blockquote kind="error" withIcon>{error?.detail?.toString()}</Blockquote>
                    )}
                    {!!orderItem && (
                        <div className="flex items-start max-w-md py-4 px-8 bg-white shadow-md rounded-lg my-10 mx-3">
                            <OrderItemCard item={orderItem}
                                pickup={
                                    orderItem?.state === 'CONFIRMED' && user?.role === Role.SupplierDriver ? handlePickup : undefined
                                }
                                undoPickup={
                                    orderItem?.state === 'LOADED' && user?.role === Role.SupplierDriver ? handleUndoPickup : undefined
                                }
                                handover={
                                    orderItem?.state === 'LOADED' && user?.role === Role.SupplierDriver ? handleHandover : undefined
                                }
                                acceptReturn={
                                    orderItem?.state === 'DELIVERED' && user?.role === Role.SupplierDriver ? handleAcceptReturn : undefined
                                }
                                accept={
                                    orderItem?.state === 'DELIVERED' && user?.role === Role.SchoolRepresentative ? handleAccept : undefined
                                }
                                loss={
                                    orderItem?.state === 'DONE' && user?.role === Role.SchoolRepresentative ? handleLoss : undefined
                                }
                                modify={
                                    orderItem?.state === 'DELIVERED' && user?.role === Role.SchoolRepresentative ? handleModify : undefined
                                }
                                reject={
                                    orderItem?.state === 'DELIVERED' && user?.role === Role.SchoolRepresentative ? handleReject : undefined
                                }
                            />
                        </div>
                    )}
                    {!(error || orderItem) && <LoadingCenter />}
                </>
            )}
        </>
    );
};
