import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Checkbox, Table} from "@wfp/ui";
import {Crate, KVPair, ListPage, OrderItem, OrderItemState, Role, Sortable} from "../../models/types";
import {useLoggedUser} from "../../hooks/user";
import {TablePagination} from "../commons/table-pagination/table-pagination";

interface Props {
    hidePagination?: boolean;
    page: ListPage<OrderItem>;
    loadPage: (page: number, pageSize?: number, filtering?: KVPair[], sorting?: string[]) => void;
    onSelectOrderItems?: (orderItemsId: number[]) => void;
    onClickOrderItem?: (o: OrderItem) => void;
    sortInfo?: Sortable[];
    sort?: (field: string) => void;
}

const headers = ["school_name", "item1", "quantity_item1_AM", "quantity_item1_PM", "item2", "quantity_item2_AM", "quantity_item2_PM", "total_quantity_items_am", "total_quantity_items_pm", "total_quantity_items", "quantity_snacks_am", "quantity_snacks_pm", "snacks", "state"];

const sortInfo: Sortable[] = [
    {field: 'school_name', code: 'school_name', enabled: true, mode: 0},
    {field: '', code: 'item1', enabled: false, mode: 0},
    {field: 'quantity_item1_am', code: 'quantityItem1AM', enabled: false, mode: 0},
    {field: 'quantity_item1_pm', code: 'quantityItem1PM', enabled: false, mode: 0},
    {field: '', code: 'item2', enabled: false, mode: 0},
    {field: 'quantity_item2_am', code: 'quantityItem2AM', enabled: false, mode: 0},
    {field: 'quantity_item2_pm', code: 'quantityItem2PM', enabled: false, mode: 0},
    {field: 'total_quantity_items_am', code: 'totalQuantityItemsAM', enabled: false, mode: 0},
    {field: 'total_quantity_items_pm', code: 'totalQuantityItemsPM', enabled: false, mode: 0},
    {field: 'total_quantity_items', code: 'totalQuantityItems', enabled: false, mode: 0},
    {field: 'quantity_am', code: 'quantitySnacksAM', enabled: true, mode: 0},
    {field: 'quantity_pm', code: 'quantitySnacksPM', enabled: true, mode: 0},
    {field: 'snacks', code: 'items', enabled: false, mode: 0},
    {field: 'state', code: 'state', enabled: true, mode: 0},
];

export function OrderItemSnacksTable(props: Props) {
    const {t} = useTranslation();
    const user = useLoggedUser({});
    const [allRowsSelected, setAllRowsSelected] = useState<boolean>(false);
    const [rowsSelected, setRowsSelected] = useState<boolean[]>([]);
    const [sortables, setSortables] = useState<Sortable[]>(sortInfo);

    useEffect(() => {
        if (!!props.page) {
            const allRowsSelected = props.page.results.map(() => false);
            setRowsSelected(allRowsSelected);
        }
    }, [props.page]);

    useEffect(() => {
        if (!!props.sortInfo) {
            const sorts = headers.map((header) => {
                const sort = props.sortInfo?.find((sort) => sort.code === header);
                return !!sort ? sort : {code: header, field: header, mode: 0, enabled: false}
            });
            setSortables(sorts);
        }
    }, [props.sortInfo]);

    function checkOrderItem(idx: number) {
        if (idx === -1) {
            setAllRowsSelected(!allRowsSelected);
            rowsSelected.forEach((_, index) => rowsSelected[index] = !allRowsSelected);
        } else {
            rowsSelected[idx] = !rowsSelected[idx];
            const selected = rowsSelected.filter(f => f);
            if (selected.length === 0) {
                setAllRowsSelected(false);
                idx = -1;
            }
            if (selected.length === rowsSelected.length) {
                setAllRowsSelected(true);
                idx = -1;
            }
        }
        setRowsSelected([...rowsSelected]);
        if (!!props.onSelectOrderItems) {
            if (idx === -1) {
                props.onSelectOrderItems(allRowsSelected ? [] : [-1]);
            } else {
                props.onSelectOrderItems(props.page.results.filter((_, idx) => rowsSelected[idx]).map((oi) => oi.id));
            }
        }
    }

    function handleSorting(idx: number) {
        if (!!sortables && !!props.sort) {
            const sortable = sortables[idx];
            if (!sortable.enabled) {
                return;
            }
            sortable.mode = (sortable.mode + 1) % 3;
            setSortables([...sortables]);
            const value = sortable.mode === 1 ? `+${sortable.field}` : sortable.mode === 2 ? `-${sortable.field}` : sortable.field;
            props.sort(value);
        }
    }

    function handleClickOrderItem(item: OrderItem): void {
        if (!!props.onClickOrderItem && (item.state === OrderItemState.CONFIRMED || OrderItemState.PLANNED) && user?.role === Role.PartnerManager) {
            props.onClickOrderItem(item);
        }
    }

    function formatContentNameQuantityRow(contentNames: string[] | null, crates: Crate[] | null): string[] {
        // Returns the quantity of each crates in the orderItem item1,AM1,PM1,Item2,AM2,PM2,TotalAM,TotalPM,Total
        const rowContentNameQuantity: string[] = [contentNames?.at(0) || '0/0', '0/0', '0/0', contentNames?.at(1) || '0/0', '0/0', '0/0', '0/0', '0/0', '0/0'];
        if (!crates || !contentNames) {
            return rowContentNameQuantity;
        }
        const bufferAM = {
            accepted1: 0,
            quantity1: 0,
            accepted2: 0,
            quantity2: 0,
            acceptedTotal: 0,
            quantityTotal: 0,
        };
        const bufferPM = {
            accepted1: 0,
            quantity1: 0,
            accepted2: 0,
            quantity2: 0,
            acceptedTotal: 0,
            quantityTotal: 0,
        };
        const AM1 = 1;
        const PM1 = 2;
        const AM2 = 4;
        const PM2 = 5;
        const AMT = 6;
        const PMT = 7;
        const TOT = 8;
        crates.forEach((c) => {
            if (c.contentName === contentNames[0] && c.crateType === 0) {
                bufferAM.accepted1 = c.acceptedQuantity;
                bufferAM.quantity1 = c.quantity;
            } else if (c.contentName === contentNames[0] && c.crateType === 1) {
                bufferPM.accepted1 = c.acceptedQuantity;
                bufferPM.quantity1 = c.quantity;
            } else if (c.contentName === contentNames[1] && c.crateType === 0) {
                bufferAM.accepted2 = c.acceptedQuantity;
                bufferAM.quantity2 = c.quantity;
            } else if (c.contentName === contentNames[1] && c.crateType === 1) {
                bufferPM.accepted2 = c.acceptedQuantity;
                bufferPM.quantity2 = c.quantity;
            }
        });
        bufferAM.acceptedTotal = bufferAM.accepted1 + bufferAM.accepted2;
        bufferAM.quantityTotal = bufferAM.quantity1 + bufferAM.quantity2;
        bufferPM.acceptedTotal = bufferPM.accepted1 + bufferPM.accepted2;
        bufferPM.quantityTotal = bufferPM.quantity1 + bufferPM.quantity2;
        rowContentNameQuantity[AM1] = `${bufferAM.accepted1}/${bufferAM.quantity1}`;
        rowContentNameQuantity[PM1] = `${bufferPM.accepted1}/${bufferPM.quantity1}`;
        rowContentNameQuantity[AM2] = `${bufferAM.accepted2}/${bufferAM.quantity2}`;
        rowContentNameQuantity[PM2] = `${bufferPM.accepted2}/${bufferPM.quantity2}`;
        rowContentNameQuantity[AMT] = `${bufferAM.acceptedTotal}/${bufferAM.quantityTotal}`;
        rowContentNameQuantity[PMT] = `${bufferPM.acceptedTotal}/${bufferPM.quantityTotal}`;
        rowContentNameQuantity[TOT] = `${bufferAM.acceptedTotal + bufferPM.acceptedTotal}/${bufferAM.quantityTotal + bufferPM.quantityTotal}`;
        return rowContentNameQuantity;
    }

    return (
        <div className="overflow-x-auto">
            <Table>
                <thead>
                <tr className="item-row-head">
                    {user?.role === Role.SupplierManager && (
                        <th>
                            <Checkbox id='all' labelText={''} name='row-select' value={-1}
                                      checked={allRowsSelected || false}
                                      onClick={() => checkOrderItem(-1)}></Checkbox>
                        </th>
                    )}
                    {headers.map((header, idx) => (
                        <th key={idx} onClick={() => handleSorting(idx)}
                            className={props.sort && sortables?.at(idx)?.enabled ? "cursor-pointer" : ""}>
                            {t(header)}
                            {!!sortables?.at(idx) && (
                                <span className="ml-0.5">
                                {sortables[idx].mode === 2 ? '\u25bc' : sortables[idx].mode === 0 ? '\u2000' : '\u25b2'}
                            </span>
                            )}
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {!!props.page && props.page.results.map((item, idx) => (
                    <tr className={`item-row ${(item.state === OrderItemState.CONFIRMED || OrderItemState.PLANNED) && user?.role === Role.PartnerManager ? 'hover:bg-slate-100 cursor-pointer' : ''}`}
                        onClick={() => handleClickOrderItem(item)} key={item.id}>
                        {user?.role === Role.SupplierManager && (
                            <td>
                                <Checkbox id={`${item.id}`} labelText={''} name="row-select" value={item.id}
                                          checked={rowsSelected[idx] || false}
                                          onClick={() => checkOrderItem(idx)}></Checkbox>
                            </td>
                        )}
                        <td className="item-col text-start">
                            {item.schoolName}
                        </td>
                        {formatContentNameQuantityRow(item.contentNames || ['', ''], item.crates).map((quantity, idx) => (
                            <td key={idx} className="item-col text-start">
                                {quantity}
                            </td>
                        ))}
                        <td className="item-col text-start">
                            {item.quantityAm}
                        </td>
                        <td className="item-col text-start">
                            {item.quantityPm}
                        </td>
                        <td className="item-col text-start">
                            {item.quantity}
                        </td>
                        <td className="item-col text-start">
                            <div className={`bg-order-state-${item.state} rounded-3xl`}>
                                <p className={`ml-2 text-order-state-${item.state}`}>{item.state}</p>
                            </div>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
            {!props.hidePagination && (
                <TablePagination page={props.page} loadPage={props.loadPage}/>
            )}
        </div>
    )
}
