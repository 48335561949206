import React, { useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Blockquote, Breadcrumb, BreadcrumbHome, BreadcrumbItem, Button, Tabs, Tab, Text, Modal } from "@wfp/ui";
import { ListPage, OrderHistoryData, OrderItem, Role, ServerError, OrderItemChange } from "../../models/types";
import { orderStateParse } from "../../models/validate-order";
import { confirmOrder, getHistory } from "../../restapi/order";
import { getOrderItems } from "../../restapi/order-item";
import { useLoggedUser } from "../../hooks/user";
import { useRetrieveOrder } from "../../hooks/order";
import { LoadingCenter } from "../commons/loading-center";
import { OrderItemList } from "../order-item/list";
import { OrderDelivery } from "./delivery";
import { OrderHistory } from "./history";
import { OrderSummary } from "./summary";
import { OrderItemChangeForm } from "../order-item/change-form";
import { useChangeOrderItem, useGetHistory, useRetrieveOrderItems } from "../../hooks/order-item";
import i18n from "../../i18n";

export const OrderDetail = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();
    const user = useLoggedUser({});
    const [serverError, setServerError] = useState<ServerError>();
    const [changeError, setChangeError] = useState<string>();
    const [tabIndex, setTabIndex] = useState(parseInt(localStorage.getItem('OrderDetailTabIndex') || '0'));
    const [openChangeOrderItem, setopenChangeOrderItem] = useState<boolean>(false);
    const [orderItemChange, setOrderItemChange] = useState<OrderItem>();
    const [crateObjectChange, setCrateObjectChange] = useState<OrderItemChange>();
    const { mutate, isLoading, isError, error} = useChangeOrderItem();

    const order = useRetrieveOrder({ id: Number(id), onError: (e) => setServerError(e) });
    const orderItems = useRetrieveOrderItems({ filters: [{ key: 'order', value: Number(id).toString() }], onError: (e) => setServerError(e) });
    const history = useGetHistory(Number(id), (e) => setServerError(e));
   

    function handleConfirm(): void {
        if (!user) {
            return;
        }
        if (!!order) {
            confirmOrder(order.id).then(() => navigate('/issuance')).catch((serverError) => setServerError(serverError));
        }
    }

    function handleSelectOrderItem() {
        // console.log(id)  TODO: define behavior on selection
    }

    function handleTabChange(idx: number): void {
        localStorage.setItem("OrderDetailTabIndex", idx.toString());
        setTabIndex(idx);
    }

    function handleChangeOrderItem(change: OrderItemChange): void {
        setCrateObjectChange(change);
    }

    function handleOpenModalChangeOrderItem(o: OrderItem): void {
        setOrderItemChange(o);
        setopenChangeOrderItem(true);
    }

    function handleCloseModalChangeOrderItem(): void {
        setopenChangeOrderItem(false);
        setChangeError(undefined);
    }

    function handleSaveChanges(): void {
        if(!!crateObjectChange) {
            mutate(crateObjectChange, {
                onSuccess: () => setopenChangeOrderItem(false),
                onError: (e) => setChangeError(e.message)
            });
        } else {
            handleCloseModalChangeOrderItem()
        }
    }

    return (
        <div>
            {!!serverError?.errors && (
                <Blockquote kind="error" withIcon>{serverError.errors}</Blockquote>
            )}
            {!!serverError?.detail && (
                <Blockquote kind="error" withIcon>{serverError?.detail?.toString()}</Blockquote>
            )}
            {!!order && (
                <div className="m-10">
                    <Breadcrumb className="mb-5">
                        <BreadcrumbItem>
                            <NavLink to="/"><BreadcrumbHome /></NavLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <NavLink to="/orders">{t('order')}</NavLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem disableLink>
                            {t('view_order')}
                        </BreadcrumbItem>
                    </Breadcrumb>
                    <div className="flex justify-between mb-5">
                        <div className={`${i18n.dir() === 'ltr' ? 'ml-0' : 'ml-3'} align-baseline`}>
                            <Text kind='h1'>{t("order_details")}</Text>
                        </div>
                        {!!user && user.role === Role.SupplierManager && (
                            <Button className="self-end !inline-flex" disabled={order.state !== 'REQUESTED'} onClick={handleConfirm}>{t('confirm')}</Button>
                        )}
                    </div>
                    <div className="mb-5">
                        <OrderSummary order={order} contentType={order.contentTitle} />
                    </div>
                    <Tabs className='mt-2' selected={tabIndex}>
                        <Tab onClick={() => handleTabChange(0)} label={t('info')} href='' role={'info'} selected={tabIndex === 0} tabIndex={0}>
                            {!!orderItems && (
                                <>
                                    <div className="flex items-center mb-3">
                                        <div>{t("state")}</div><div className={`bg-order-state-${order.state} rounded-3xl ml-5 mr-auto p-2`}>{t(orderStateParse(order))}</div>
                                    </div>
                                    <div className="visible md:hidden">
                                        <OrderItemList contentType={order.contentTitle} page={orderItems} mobile={true}  onClickOrderItem={handleOpenModalChangeOrderItem}/>
                                    </div>
                                    <div className="hidden md:block">
                                        <OrderItemList contentType={order.contentTitle} onClickOrderItem={handleOpenModalChangeOrderItem} page={orderItems} mobile={false} />
                                    </div>
                                </>
                            )}
                        </Tab>
                        <Tab onClick={() => handleTabChange(1)} label={t('history')} href='' role={'history'} selected={tabIndex === 1} tabIndex={1}>
                            {!!history && (
                                <OrderHistory data={history} />
                            )}
                        </Tab>
                        <Tab onClick={() => handleTabChange(2)} label={t('delivery')} href='' role={'delivery'} selected={tabIndex === 2} tabIndex={2}>
                            {!!history && (
                                <OrderDelivery data={history} />
                            )}
                        </Tab>
                    </Tabs>
                </div>
            )}
            {!(serverError || order) && (
                <LoadingCenter />
            )}
            {!!openChangeOrderItem && !!orderItemChange && (
                <Modal
                    open={true}
                    modalHeading={t('school') + ' ' + orderItemChange.schoolRef || ''}
                    modalLabel={t('edit_order') || ''}
                    primaryButtonText={t('save') || ''}
                    onRequestSubmit={handleSaveChanges}
                    onRequestClose={handleCloseModalChangeOrderItem}
                    secondaryButtonText={t('cancel') || ''}
                    primaryButtonDisabled={isLoading}
                >
                    {!!changeError &&(
                        <Blockquote kind="error" withIcon>{changeError}</Blockquote>
                    )}
                    <OrderItemChangeForm onChange={handleChangeOrderItem} orderItem={orderItemChange}></OrderItemChangeForm>
                </Modal>
            )}
        </div>

    );
};
